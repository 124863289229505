.product {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 4px solid #7dc569;
  border-radius: 20px;
}
.img-container {
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 20px;
}
.img-div {
  position: relative;
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
.product img {
  margin: 0 auto;
  border: 1px solid white;
  border-radius: 20px;
}
.product-details {
  padding: 10px;
}
.add-btn{
  bottom: 0;
  font-weight: bold;
}
.limit-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2 ;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 992px) {
  .product {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 640px) {
  .product {
    width: 92%;
    text-align: left;
    font-size: 14px;
    border: none;
  }
  .product-details {
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .img-container{
    height: 80%; 
    width: 100%;
  }
}
